import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
// import { firestorePlugin } from "vuefire";
import axios from "axios";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { getFunctions, httpsCallable } from "firebase/functions";
import moment from "moment";

Vue.prototype.moment = moment;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
// Vue.use(firestorePlugin);

Vue.prototype.$screen = Vue.observable({
  width: window.innerWidth,
  height: window.innerHeight,
});

window.addEventListener("resize", () => {
  Vue.prototype.$screen.width = window.innerWidth;
  Vue.prototype.$screen.height = window.innerHeight;
});

let app;

// onAuthStateChanged(getAuth(), (user) => {
// console.log("user", getAuth().currentUser);
// console.log("user", user);
if (!app) {
  app = new Vue({
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
}
// });

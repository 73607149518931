import Vue from "vue";
import VueRouter from "vue-router";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, getDoc, doc } from "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyASqNLCcc434B3PLmg9yT_wznzXi-GllSU",
  authDomain: "pawanfiber-client.firebaseapp.com",
  databaseURL: "https://pawanfiber-client.firebaseio.com",
  projectId: "pawanfiber-client",
  storageBucket: "pawanfiber-client.appspot.com",
  messagingSenderId: "619280188025",
  appId: "1:619280188025:web:2078aa66bf2573c4d3caa8",
  measurementId: "G-W58P921CGL",
};

initializeApp(firebaseConfig);

Vue.use(VueRouter);

// console.log("router ", getAuth());

function importComponent(path) {
  return () => import(`./../components/${path}.vue`);
}

function importPages(path) {
  return () => import(`./../views/${path}.vue`);
}

const routes = [
  {
    path: "/",
    // name: "DashboardLayout",
    component: importComponent("DashboardLayout"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        name: "dashboard",
        meta: { title: "Dashboard" },
        component: importComponent("Dashboard"),
      },
      {
        path: "/news",
        name: "news",
        meta: { title: "News" },
        component: importComponent("News"),
      },
      {
        path: "/promo",
        name: "promotion",
        meta: { title: "Promo" },
        component: importComponent("Promo"),
      },
      {
        path: "/package",
        name: "package",
        meta: { title: "Package" },
        component: importComponent("Package"),
      },
      {
        path: "/internet-package",
        name: "InternetPackage",
        meta: { title: "Internet Package" },
        component: importComponent("InternetPackage"),
      },
      {
        path: "/wifi-package",
        name: "WifiPackage",
        meta: { title: "Wifi Package" },
        component: importComponent("WifiPackage"),
      },
      {
        path: "/cctv-package",
        name: "CCTVPackage",
        meta: { title: "CCTV Package" },
        component: importComponent("CctvPackage"),
      },
      {
        path: "/tv-package",
        name: "TVPackage",
        meta: { title: "TV Package" },
        component: importComponent("TvPackage"),
      },
      {
        path: "/point",
        name: "Point",
        meta: { title: "Point" },
        component: importComponent("Point"),
      },
      {
        path: "/admin",
        name: "Admin",
        meta: { title: "Admin" },
        component: importComponent("Admin"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: { title: "Login" },
    component: importPages("Login"),
  },
  {
    path: "/register",
    name: "register",
    meta: { title: "Register" },
    component: importPages("Register"),
  },
  {
    path: "/sorry",
    name: "sorry",
    meta: { title: "RedirectNotAdmin" },
    component: importPages("RedirectNotAdmin"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

const auth = getAuth();
const db = getFirestore();
var roles = false;

async function checkUser(user) {
  const docRef = doc(db, "user", user.uid);

  try {
    await getDoc(docRef).then((snapshot) => {
      var check = snapshot.data();

      if (Object.prototype.hasOwnProperty.call(check, "roles")) {
        if (check.roles === "admin") {
          return (roles = true);
        }
      }

      return (roles = false);
    });
  } catch (e) {
    console.log(e);
    return (roles = false);
  }
}

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      await checkUser(user);

      if (requiresAuth) {
        if (roles) {
          next();
        } else {
          next({
            path: "/sorry",
          });
        }
      } else if (to.name == "register" || to.name == "login") {
        next({
          path: "/",
        });
      } else {
        next();
      }
    } else if (to.name == "register" || to.name == "login") {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  });
});

export default router;
